<template>
  <div>
    <div class="banner" id="newest_banner1">
      <div class="banner" id="newest_banner1" :style="{backgroundImage:('url('+banner+')')}">
        <!-- <img :src="banner" /> -->
        <span
          >{{ bannerNotEnText }}<i>{{ bannerEnText }}</i></span
        >
      </div>
    </div>

    <section class="ab-item1">
      <bread-crumb v-if="resData.sampleTypeName == '其他'" />
      <template v-else>
        <a href="/">{{ $t("indexnav.Home") }}</a> >
        <!-- <a :href="'/article/list?type='+resData.typeId">{{ resData[navLanguageKey] }}</a> -->
        <a style="cursor: pointer" :href="setCrumb(resData[navLanguageKey])[0].url">{{setCrumb(resData[navLanguageKey])[0].name}}</a>
        <template v-if="articleTypeId=='1559892075984457730'">
        >
        <a style="cursor: pointer" @click="window.history.go(-1)">{{ resData[navLanguageKey] }}</a> >
        <a style="cursor: pointer">{{
          resData["title"]
        }}</a>
        </template>
      </template>
    </section>

    <figure>
      <h1 class="title" v-if="articleTypeId == '1'">{{ resData.title }}</h1>
      <h2 class="futitle" v-if="articleTypeId == '1'">
        {{ companyName }} {{ publishDate }} {{ viewCountName }}：{{
          resData.readAmount
        }}
      </h2>
      <div v-html="resData.content" id="articleContent"></div>
    </figure>
  </div>
</template>
<script>
import { getAction } from "@/api/manage";
import moment from "dayjs";
import store from "@/store";
import { PageType } from "@/utils/constant";
// window.Location.go(-1)
export default {
  data() {
    return {
      url: {
        article: "/jeecg-system/common/frontend/article/queryById",
      },
      resData: {},
      articleTypeId: "",
      companyName: "锦欣生殖医疗集团有限公司",
      publishDate: "",
      viewCountName: "", // 阅读量 名称
      window: window,
      banner: "",
      bannerNotEnText: "",
      bannerEnText: "",
    };
  },
  computed: {
    navLanguageKey() {
      if (store.state.nowlanguage == "zh") return "sampleTypeName";
      if (store.state.nowlanguage == "tr") return "tranditionalTypeName";
      if (store.state.nowlanguage == "en") return "enTypeName";
      if (!store.state.nowlanguage) return "sampleName";
    },
    vuexData() {
      return this.$store.state.nowlanguage;
    },
  },
  created() {
    let self = this;
    getAction(this.url.article, { id: this.$route.query.id }).then((res) => {
      console.log(res);
      if (res.message == "未找到对应实体") {
        this.$router.push("/404");
      }
      this.resData = res.result;
      this.articleTypeId = res.result.typeId;
      this.publishDate = moment(res.result.publishDate).format("YYYY-MM-DD");
      // 针对高管信息，对文章详情页banner做特殊处理
      if (
        this.articleTypeId === "1559892075984457730" ||
        this.articleTypeId === "1563057085736816641"
      ) {
        // 高管信息
        this.banner = require("../../assets/images/investors/investor_banner01.jpg");
        switch (this.$store.state.nowlanguage) {
          case "en":
            this.bannerEnText = "About Us";
            this.bannerNotEnText = "";
            break;
          case "tr":
            this.bannerEnText = "About Us";
            this.bannerNotEnText = "關於我們";
            break;
          default:
            this.bannerEnText = "About Us";
            this.bannerNotEnText = "关于我们";
        }
      } else {
        // 其他文章
        // this.banner = require("../../assets/images/news/newest_banner_01.gif");
           // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.WENZHANGXIANGQIN,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });
        getAction("/jeecg-system/frontend/articleType/list_all", {}).then(
          (res) => {
            let matchedItem = res.result.find(
              (item) => item.id === self.articleTypeId
            );
            if (matchedItem !== undefined) {
              switch (this.$store.state.nowlanguage) {
                case "en":
                  self.bannerEnText = matchedItem.enName;
                  self.bannerNotEnText = "";
                  break;
                case "tr":
                  self.bannerEnText = matchedItem.enName;
                  self.bannerNotEnText = matchedItem.tranditionalName;
                  break;
                default:
                  self.bannerEnText = matchedItem.enName;
                  self.bannerNotEnText = matchedItem.sampleName;
              }
            }
          }
        );
      }
    });

    this.setNames(this.$store.state.nowlanguage);
  },
  methods: {
    setCrumb(url) {
      let treeList = store.state.navTree;
      let stock = [];
      let going = true;
      console.log(url);
      let warker = function (arr, url) {
        arr.forEach((item) => {
          if (!going) return;
          stock.push({name:item[store.state.navLanguageKey],url:item.crumb});
          // stock.push(item);
          if (item[store.state.navLanguageKey] == url) {
            going = false;
          } else if (item.childList) {
            warker(item.childList, url);
          } else {
            stock.pop();
          }
        });
        if (going) stock.pop();
      };
      warker(treeList, url);
      console.log(stock);
      return stock;
    },
    // 设置公司名称和阅读量名称
    setNames(language) {
      if (language === "en") {
        this.companyName = "Jinxin Fertility Group Limited";
        this.viewCountName = "Clicks";
      } else if (language === "tr") {
        this.companyName = "錦欣生殖醫療集團有限公司";
        this.viewCountName = "阅读";
      } else {
        this.companyName = "锦欣生殖医疗集团有限公司";
        this.viewCountName = "阅读";
      }
    },
  },
  watch: {
    "this.$store.state.nowlanguage"(newval, oldval) {
      this.setNames(newval);
    },
  },
};
</script>

<style scoped lang='less'>
.title {
  color: #165da3;
  font-weight: 500;
  font-size: 1.6em;
  text-align: center;
  margin-bottom: 15px;
}
.futitle {
  font-size: 0.9rem;
  text-align: center;
  font-style: normal;
  color: #898989;
  display: block;
  margin-bottom: 2em;
}
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

figure,
article {
  margin: 2em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

figure figcaption {
  background: #165da3;
  font-size: 1.2em;
  color: #fff;
  font-weight: 500;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

figure div {
  padding: 0.6em 0;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*line-height: 28px;*/
  line-height: 28px;
  font-size: 18px;
}

#articleContent {
  font-size: 16px;
  // font-family: "宋体";
  ::v-deep a {
    -webkit-user-select: none;
    color: #666666;
    font: 14px/2 "宋体", Arial !important;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  ::v-deep a:hover {
    color: #e60044 !important;
  }
}
#articleContent figure div a {
  display: flex;
  flex-direction: column;
  width: 31%;
  margin-right: 3.5%;
  margin-bottom: 2em;
}

figure div a:nth-child(3),
figure div a:nth-child(6),
figure div a:nth-child(9),
figure div a:nth-child(12) {
  margin-right: 0;
}

figure div a div {
  max-width: 24em;
  max-height: 16em;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

figure div a div img {
  width: 100%;
}

figure div a strong {
  height: 1.4em;
  overflow: hidden;
  color: #595757;
  line-height: 1.4em;
  margin-top: 0.6em;
}

figure div a span {
  font-size: 0.9em;
  line-height: 2em;
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;
  color: #898989;
}

figure div a p {
  font-size: 0.6em;
  line-height: 2.6em;
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;
  color: #595757;
  height: 5.2em;
  overflow: hidden;
}

figure > div a:hover strong {
  color: #e60044;
}

.page {
  margin: 2em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.page ul {
  display: flex;
  justify-content: center;
}

.page ul li {
  line-height: 1.3em;
  font-size: 1em;
  padding: 0.4em 1.2em;
  margin-right: 0.6em;
}

.page ul li.thisclass {
  color: #165da3;
  font-weight: bold;
}

.page ul a {
  display: block;
}

.page ul a:last-child {
  margin-right: 0;
}

article {
  margin: 0 auto;
  padding: 3em 0;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
}

article h3 {
  font-weight: bold;
}

article h1,
article h2 {
  text-align: center;
}

article h1 {
  color: #165da3;
  font-weight: 500;
  font-size: 1.6em;
}

article h1:after,
article h2:after {
  margin: 0 auto 0 auto;
  width: 1.5em;
  height: 0.4em;
  content: "";
  display: block;
}

article dfn {
  font-size: 0.9rem;
  text-align: center;
  font-style: normal;
  color: #898989;
  display: block;
}

article > div {
  margin-top: 2em;
  padding: 1em 0.5em;
  line-height: 2;
}

article > div img {
  max-width: 800px;
  width: 100%;
  height: auto;
}

article > div > * {
  margin-top: 1em;
}

article > div > h2 {
  margin-top: 3em;
}

article > div ul {
  width: 100%;
  overflow: hidden;
}

article > div li {
  float: left;
  display: inline;
  padding: 1em 0;
  min-width: 25%;
  max-width: 100%;
  line-height: 1.5;
}

article > div li strong {
  color: #2693d0;
  display: block;
}

article > div dl {
  width: 95%;
  border-left: #2693d0 1px solid;
}

article > div dl dd {
  padding-left: 1em;
  position: relative;
}

article > div dl dd + dd {
  margin-top: 1em;
}

article > div dl dd span {
  display: block;
  font-weight: bold;
  color: #2693d0;
}

article > div dl dd:before {
  width: 0.5em;
  height: 0.5em;
  content: "";
  background-color: #f00;
  position: absolute;
  top: 0.8em;
  left: -0.25em;
  z-index: 2;
  display: block;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure {
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure div a strong {
    margin-top: 0;
  }

  figure div a p {
    line-height: 2.3em;
  }
}

@media screen and (max-width: 1150px) {
  figure div a span {
    font-size: 1em;
  }

  figure div a strong {
    line-height: 1em;
  }

  figure div a p {
    line-height: 2em;
  }
}

@media screen and (max-width: 768px) {
  figure div {
    /*display: flex;*/
    /*flex-direction: column;*/
  }
  /deep/ #articleContent{
    img{
      max-width: 100%!important;
    }
  }
  figure div a {
    width: 100%;
  }

  figure div a div {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }

  figure div a strong {
    margin-top: 0;
  }
}
</style>
